
import { Link } from 'gatsby';
import React from 'react'
import "../styles/InlineTableOfContents.sass"

export default function InlineTableOfContents ({ headings }) {
  console.log(headings);
  return (
    <section className="inline-table-of-contents">
      {headings.map((heading, index) => (
        <p key={index} className={`depth-${heading.depth}`}><Link to={`#${heading.id}`}>{heading.value}</Link></p>
      ))}
      <br/>
    </section>
  )
}