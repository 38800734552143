import React, { useEffect, useState } from 'react'
import { getPageIdFromSlug } from '../../../../utils/tableOfContentUtils';
import "../styles/ArticleAnchors.sass"

/**
 * Anchor for the page
 */

const PageAnchor = ({ anchor, pageId }) => (
  <a 
    className={`anchor-link ${anchor.pageId === pageId ? 'active' : ''}`}
    href={anchor.slug} 
    >{anchor.name}
  </a>
);

/**
 * Anchor for the anchor itself
 */

const ActualAnchor = ({ anchor }) => (
  <a 
    className={`anchor-link`}
    href={`#${anchor.id}`} 
    >{anchor.name}
  </a>
)

function ArticleAnchors (props) {
  const [scrollPercent, setScrollPercent] = useState(0)
  const { message, toc, slug } = props;

  const pageId = getPageIdFromSlug(slug);
  const page = toc && toc.find((p) => p.pageId === pageId);

  useEffect(() => {
    const onScroll = () => {
        var h = document.documentElement, 
            b = document.getElementById('html-wrapper'),
            st = 'scrollTop',
            sh = 'scrollHeight';

        
        var docElementScrollTop = h[st];

        var htmlScrollHeight = b[sh];

        setScrollPercent(
          (docElementScrollTop - h.clientHeight) / htmlScrollHeight * 100
        );
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);

  }, [scrollPercent]);
  
  return (
    <div style={{ opacity: toc && toc.length === 0 ? 0 : 1}} 
      className="article-anchors">
      <div 
        className="message" 
        dangerouslySetInnerHTML={{ __html: message}}></div>
      <div>
        { toc && toc[0] && toc[0].name !== null && toc.map((anchor, i) => (
          <PageAnchor key={i} pageId={pageId} anchor={anchor}/>
      ))}
      </div>
      <div className="anchor-sublist">
        { page && page.anchors && page.anchors.map((anchor, i) => (
          <ActualAnchor key={i} anchor={anchor}/>
        ))}
      </div>
      <div className="read-status">
        <div style={{ width: `${scrollPercent}%` }} className="read-status-progress"></div>
      </div>
    </div>
  )
}

export default ArticleAnchors;