
import { Link } from 'gatsby';
import React from 'react'
import { SubmitButton } from '../../buttons'
import "../styles/ArticleSeriesNext.sass"

const ArticleSeriesNext = ({ next }) => (
  <>
    <section className="next-in-series-container">
      <div className="article-container">
        <p>Next page</p>
        <h2>{next.name}</h2>
        <p>{next.description}</p>
        <Link to={next.slug}>
          <SubmitButton text="Next page →" onClick={() => {}}/>
        </Link>
      </div>
    </section>
    <div className="series-break-spacer"></div>
  </>
)

export default ArticleSeriesNext;

