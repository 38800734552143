
import React from 'react'
import PropTypes from 'prop-types'
import "../styles/Article.sass"
import HTMLContent from '../../../shared/HTMLContent'
import { kebabCase } from 'lodash'
import { Link } from 'gatsby'
import AboutTheAuthor from './AboutTheAuthor'
import Authors from '../constants/AuthorConstants'
import { Tags } from '../../tags'
import ArticleDescription from './ArticleDescription'
import SimilarArticles from './SimilarArticles'
import { SubscribeForm } from '../../../subscribe';
import ArticleCategory from './ArticleCategory'
import { ShareButtons } from '../../share-buttons';
import ArticleAnchors from './ArticleAnchors';
import { getCategoryIconAndBanner } from '../../../../utils/blog';
import { Comments } from '../../../comments';
import ArticleGuestPostHeading from './ArticleGuestPostHeading'
import ArticleSeriesNext from './ArticleSeriesNext'
import { getPageIdFromSlug } from '../../../../utils/tableOfContentUtils'
import { ArticleMeta } from '../../date-posted'
import InlineTableOfContents from './InlineTableOfContents'
import { Advertisement } from '../../advertisement/Advertisement'
import { AdvertisementController } from '../../advertisement/AdvertisementController'
import Resources from '../../../home/components/Resources'

class Article extends React.Component {
  constructor (props) {
    super(props);
    this.getUniquePageIdentifier = this.getUniquePageIdentifier.bind(this);
    this.state = {
      anchors: []
    }
  }

  componentWillUnmount () {
    if (typeof window !== 'undefined') {
      try {
        document.querySelector('.article-anchors').remove()
      } catch (err) {
      }
    }
  }

  getUniquePageIdentifier() {
    return typeof window !== 'undefined' && window.location.href
      ? typeof window !== 'undefined' && window.location.href
      : 'https://khalilstemmler.com'
  }

  getAnchors () {
    let anchors = [];
    if (typeof window !== 'undefined') {
      const nodeList = document.querySelectorAll('a[name]');
      if (nodeList.length !== 0) {
        nodeList.forEach((node) => {
          anchors.push(node);
        })
      }
    }
    return anchors;
  }

  hasAnchors () {
    return !!this.getAnchors() && this.getAnchors().length !== 0;
  }

  mountAnchors () {
    setTimeout(() => {
      if (this.hasAnchors()) {
        try {
          document.querySelector('.article-anchors').style.opacity = "1";
        } catch (err) {
          console.log('Couldnt show article anchors', err)
        }
        this.setState({ 
          ...this.state, 
          anchors: this.getAnchors() 
        })
      }
    }, 3000)
  }

  componentDidMount () {
    this.mountAnchors();
  }

  isBlogPost () {
    return this.props.templateKey === "blog-post";
  }

  getImage () {

    if (this.isBlogPost() && this.props.image) {
      return this.props.image
    } 

    if (this.isBlogPost()) {
      const iconAndBanner = getCategoryIconAndBanner(this.props.category)
      return iconAndBanner.banner;
    }

    return this.props.image;
  }

  isGuestPost () {
    return this.props.guestPost === true
  }

  isSeries () {
    return !!this.props.seriesId;
  }

  isLastSeries () {
    const { slug, tableOfContents } = this.props;
    const pageId = getPageIdFromSlug(slug);
    const index = tableOfContents.findIndex((el) => el.pageId === pageId);
    return index === tableOfContents.length - 1;
  }

  getNextInSeriesProps () {
    if (this.isLastSeries()) {
      throw new Error("[LastInSeriesError] - No more pages left.")
    }
    const { slug, tableOfContents } = this.props;
    const pageId = getPageIdFromSlug(slug);
    const index = tableOfContents.findIndex((el) => el.pageId === pageId);
    const next = tableOfContents[index + 1];
    return next;
  }
  
  render () {
    const props = this.props;
    const { 
      title, 
      html, 
      category, 
      tags, 
      description, 
      slug, 
      anchormessage,
      tableOfContents,
      updated,
      headings,
      withInlineTableOfContents
    } = props;
    const fullUrl = `https://khalilstemmler.com${slug}`;
    const anchors = this.hasAnchors() ? this.getAnchors() : [];
    const image = this.getImage();
    let controller = new AdvertisementController({
      currentDate: new Date(),
      launchDateStart: new Date('October 25, 2024'),
      launchDateEnd: new Date('October 31, 2024'),
      adType: 'discount'
    });

    return (
      <section className="article-container">
        {/* <Advertisement controller={controller}/> */}
        <h1 className="article-title">{title}</h1>
        <ArticleAnchors 
          slug={slug}
          toc={tableOfContents} 
          message={anchormessage} 
          anchors={anchors}
        />
        <ArticleCategory category={category}/>
        <ArticleMeta isUpdatedTime={true} date={updated} />

        <ArticleDescription description={description}/>
        {tags && tags.length !== 0 && <>
          <Tags tags={tags}/>        
          <br/>
        </>}
        <img src={image}/>
        {this.isGuestPost() ? <ArticleGuestPostHeading author={this.props.guestPostAuthor}/> : ''}
        
        <div id="html-wrapper">
          {withInlineTableOfContents && anchors.length === 0 && <InlineTableOfContents headings={headings}/>}
            <HTMLContent 
              content={html}
              
            />
        </div>

        {this.isSeries() && !this.isLastSeries() ? (
          <ArticleSeriesNext
            next={this.getNextInSeriesProps()}
          />
        ): (
          <>
            <hr/>
            <br/>
          </>
        )}

        {/* <h3>Discussion</h3>
        <p>Liked this? Sing it loud and proud 👨‍🎤.</p>
        <ShareButtons
          url={fullUrl}
          title={title}
        />
        <br/> */}

        {/* <Comments comments={comments}/> */}
        {/* <br/> */}

        {/* <Advertisement controller={controller}/> */}

        <h3>Stay in touch!</h3>
        <SubscribeForm/>
        <br/>
        <br/>
        
        <p>View more in <Link to={`/articles/categories/${kebabCase(category)}`}>{category}</Link></p>
        
        <br/>
        {/* <a href="https://solidbook.io">
          <img src="/img/resources/solid-book/book-banner.png"/>
        </a> */}
        <hr/>
        {/* <h2>You may also enjoy...</h2>
        <p>A few more related articles</p>
        <SimilarArticles category={category} tags={tags} currentArticleSlug={slug}/> */}

        {/* <HorizonalAd/> */}
      </section>
    )
  }
}

export default Article;

Article.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  date: PropTypes.string.isRequired,
  category: PropTypes.string,
  description: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  html: PropTypes.string,
  comments: PropTypes.arrayOf(PropTypes.any)
}